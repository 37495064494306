define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    loadingAds: true
  });
});