define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-chooser", ["exports", "@ember/object", "discourse-common/lib/helpers", "select-kit/components/multi-select"], function (_exports, _object, _helpers, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    classNames: ["house-ads-chooser"],
    filterable: true,
    filterPlaceholder: "admin.adplugin.house_ads.filter_placeholder",
    tokenSeparator: "|",
    allowCreate: false,
    allowAny: false,
    settingValue: "",
    valueAttribute: null,
    nameProperty: null,
    value: (0, _object.computed)("settingValue", function () {
      return this.settingValue.toString().split(this.tokenSeparator).filter(Boolean);
    }),
    // TODO: kept for legacy, remove when Discourse is 2.5
    mutateValues(values) {
      this.set("settingValue", values.join(this.tokenSeparator));
    },
    computeValues() {
      return this.settingValue.split(this.tokenSeparator).filter(Boolean);
    },
    content: (0, _object.computed)("choices", function () {
      return (0, _helpers.makeArray)(this.choices);
    }),
    actions: {
      onChange(value) {
        const settingValue = (0, _helpers.makeArray)(value).join(this.tokenSeparator);
        this.onChange?.(settingValue);
      }
    }
  });
});