define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-category-selector", ["exports", "select-kit/components/category-selector"], function (_exports, _categorySelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HouseAdsCategorySelector extends _categorySelector.default {
    get value() {
      return this.selectedCategories.map(c => c.id);
    }
  }
  _exports.default = HouseAdsCategorySelector;
});