define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-index", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    moreSettings() {
      this.router.transitionTo("adminSiteSettingsCategory", "ad_plugin");
    }
  }, [["method", "moreSettings", [_object.action]]]));
});